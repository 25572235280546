export const singleLocatiesData = {
    init() {
        // JavaScript to be fired on all pages
        $('.slider__content').slick({
            dots: false,
            arrows: true,
            slidesToShow: 3,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
