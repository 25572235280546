export default {
    init() {
        $('.locations__nav').bind('touchstart', function(){
            $('.swipe__icon').fadeOut();
        });

        $(function() {
            if ($(window).width() > 1199) {
                $('.col__news:nth-child(3n-2)').find('.front_block_news').addClass('front_block_news--large');
                $('.col__news:nth-child(3n-2)').removeClass('col-2').toggleClass('col-4');
                $('.col__news:nth-child(7)').addClass('col-news--offset');
            } else {
                $('.col__news:nth-child(1)').find('.front_block_news').addClass('front_block_news--large');
                $('.col__news:nth-child(1)').removeClass('col-2').toggleClass('col-4');
                $('.col__news:nth-child(6)').find('.front_block_news').addClass('front_block_news--large');
                $('.col__news:nth-child(6)').removeClass('col-2').toggleClass('col-4');
                $('.col__news:nth-child(6)').addClass('col-news--offset');
            }
        });
    },
    finalize() {
    },
};
