/* eslint-disable */
export const templateContactData = {
    init() {
        // JavaScript to be fired on all pages

        function new_map( $el ) {

            // var
            var $markers = $el.find('.marker');

            var customStyle = [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ];

            // vars
            var args = {
                zoom        : 6,
                center      : new google.maps.LatLng(0, 0),
                mapTypeId   : google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                styles: customStyle,
                zoomControl: true
            };

            // create map
            var map = new google.maps.Map( $el[0], args);

            // add a markers reference
            map.markers = [];

            // add markers
            $markers.each(function(){
                add_marker( $(this), map );
            });

            // center map
            center_map( map );

            // return
            return map;
        }

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	$marker (jQuery element)
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        //declare infowindow in global space
        var infowindow = new google.maps.InfoWindow();

        function add_marker( $marker, map ) {
            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

            const symbol = {
                path: "M 30.00,15.00\n" +
                    "C 30.00,21.00 28.00,22.80 16.50,39.20\n" +
                    "15.70,40.20 14.30,40.20 13.50,39.20\n" +
                    "2.00,22.80 0.00,21.00 0.00,15.00\n" +
                    "0.00,6.80 6.80,0.00 15.00,0.00\n" +
                    "23.20,0.00 30.00,6.80 30.00,15.00 Z\n" +
                    "M 23.90,11.60\n" +
                    "C 23.90,11.60 21.90,9.60 21.90,9.60\n" +
                    "21.60,9.30 21.00,9.30 20.70,9.60\n" +
                    "20.70,9.60 13.10,17.10 13.10,17.10\n" +
                    "13.10,17.10 9.80,13.80 9.80,13.80\n" +
                    "9.50,13.50 8.90,13.50 8.60,13.80\n" +
                    "8.60,13.80 6.60,15.80 6.60,15.80\n" +
                    "6.30,16.10 6.30,16.70 6.60,17.00\n" +
                    "6.60,17.00 12.50,23.00 12.50,23.00\n" +
                    "12.80,23.30 13.40,23.30 13.70,23.00\n" +
                    "13.70,23.00 23.90,12.80 23.90,12.80\n" +
                    "24.20,12.50 24.30,11.90 23.90,11.60 Z",
                strokeColor: $marker.attr('data-color'),
                fillColor: $marker.attr('data-color'),
                fillOpacity: 1,
            };

            var image = {
                url: '/app/themes/sage/resources/assets/svg/map-marker-alt-solid.svg',
                size: new google.maps.Size(26, 38),
                scaledSize: new google.maps.Size(26, 38)
            };

            // create marker
            var marker = new google.maps.Marker({
                position	: latlng,
                map			: map,
                icon        : symbol
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() )
            {
                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {
                    //add contentString using this syntax
                    infowindow.setContent($marker.html());
                    infowindow.open( map, marker );
                });
            }
        }

        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	4.3.0
        *
        *  @param	map (Google Map object)
        *  @return	n/a
        */

        function center_map( map ) {
            // vars
            var bounds = new google.maps.LatLngBounds();
            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){
                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
                bounds.extend( latlng );
            });

            // only 1 marker?
            if( map.markers.length == 1 )
            {
                // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 6 );
            }
            else
            {
                // fit to bounds
                map.setCenter( bounds.getCenter() );
                if ($(window).width() < 767) {
                    map.setZoom(12);
                } else {
                    map.setZoom(13);
                }
            }

        }

        /*
        *  document ready
        *
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type	function
        *  @date	8/11/2013
        *  @since	5.0.0
        *
        *  @param	n/a
        *  @return	n/a
        */
        // global var
        var map = null;
        $(document).ready(function(){
            $('.acf-map').each(function(){
                // create map
                map = new_map( $(this));
            });
        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
