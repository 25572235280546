export default {
    init() {
        // JavaScript to be fired on all pages
        const tippy = require('tippy.js').default;
        // Dropdown menu for mobile
        dropdownMenu();
        function dropdownMenu() {
            if ($(window).width() < 768)
                $('.dropdown-toggle__content').addClass('dropdown-menu');
            else
                $('.dropdown-toggle__content').removeClass('dropdown-menu');
        }

        // Menu menu for desktop
        megaMenu();
        function megaMenu() {
            if ($(window).width() > 991)
                $('#menu-primary-navigation > li.menu-item-has-children').addClass('has__mega-menu');
            else
                $('#menu-primary-navigation > li.menu-item-has-children').removeClass('has__mega-menu');
        }

        $('.dropdown__custom > .btn').click(function () {
            var clicks = $(this).data('clicks');
            if (clicks) {
                $('.dropdown__custom__list').hide();
                $(this).parent('.dropdown__custom').removeClass('dropdown__custom--show');
            } else {
                $('.dropdown__custom__list').show();
                $(this).parent('.dropdown__custom').addClass('dropdown__custom--show');
            }
            $(this).data('clicks', !clicks);
        });

        $('.header__menu .menu-item.has__mega-menu > .sub-menu > li').hover(function () {
            $('.menu-item__title').remove();
            $(this).children('a').next('.sub-menu').prepend('<li class="menu-item__title">' + $(this).children('a').text() + '</li>');
        });

        // Submenu toggle
        if ($('.menu-item-has-children').length)
            $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>');
        $('.menu-item-touch').on('click', function () {
            $(this).parent('.menu-item').toggleClass('open');
        });

        // Some common design tweeks
        designTweeks();
        function designTweeks() {
            if ($(window).width() < 992)
                $('.locations__content').attr('style', 'min-height:' + ($('.locations__content__right').height() + $('.locations__content__left').height()) + 'px');
            else
                $('.locations__content').attr('style', 'min-height:' + $('.locations__content__left img').height() + 'px');
        }

        $(window).resize(function () {
            designTweeks();
            dropdownMenu();
        });

        $(document).ready(function() {
            // Use event delegation for dynamically loaded elements
            $(document).on('change', '#gform_fields_44 input[type="radio"]', function() {
                // Unselect other radio buttons in the same group
                let currentName = $(this).attr('name');
                $('#gform_fields_44 input[type="radio"]').each(function () {
                    if ($(this).attr('name') !== currentName) {
                        $(this).prop('checked', false);
                    }
                })
            });
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        // Slick Slider fix
        if ($('.slick-slider').length)
            $('.slick-slider').css('opacity', '1');

        // Scroll to section
        $('.scroll-to-section').click(function () {
            var targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top - 60,
                }, 'slow');
            }
        });

        // Scroll to section
        var scrollLeftPos = 0;
        $('.locations__nav--next').click(function () {
            if (scrollLeftPos < ($('.locations__nav__item').width() * 3)) {
                scrollLeftPos = scrollLeftPos + $('.locations__nav__item').width();
                $('.locations__nav__scroll').animate({ scrollLeft: scrollLeftPos }, 500);
            }
            else {
                scrollLeftPos = 0;
                $('.locations__nav__scroll').animate({ scrollLeft: scrollLeftPos }, 500);
            }
        });

        // locations fadein effect
        $('.locations__content__item:first-child').addClass('locations__content__item--show');
        $('.locations__nav__item:first-child .locations__nav__link').addClass('locations__nav__link--active');
        $('.locations__nav__item').not('.locations__nav__item--agoraonderwijs').hover(function () {
            $('.locations__content__item').removeClass('locations__content__item--show');
            $('.locations__nav__item').children('.locations__nav__link').removeClass('locations__nav__link--active');

            var rel = $(this).attr('rel');
            $('#' + rel).addClass('locations__content__item--show');
            $(this).children('.locations__nav__link').addClass('locations__nav__link--active');
        });

        $('.header__btn').click(function () {
            $('body').removeClass('header__menu__open');
            var clicks = $(this).data('clicks');
            if (clicks) {
                $('body').removeClass('header__sublinks--open');
            } else {
                $('body').addClass('header__sublinks--open');
            }
            $(this).data('clicks', !clicks);
        });

        var openSidebar = function(){
            $('body').addClass('header__sublinks--open');
            $('.header__sublinks').addClass('open');
        }
        var closeSidebar = function(){
            $('body').removeClass('header__sublinks--open');
            $('.header__sublinks').removeClass('open');
        }

        $('.header__btn').click( function(event) {
            event.stopPropagation();
            if( $('.header__sublinks').is('.open') ) {
                closeSidebar();
            } else {
                openSidebar();
            }
        });

        $(document).click( function(event){
            if ( !$(event.target).closest('.header__sublinks').length ) {
                closeSidebar();
            }
        });

        // Slick slider on dots click animation
        $('.slick-dots li').on('mouseenter', '.slick-slide', function (e) {
            var $currTarget = $(e.currentTarget),
                index = $currTarget.data('slick-index'),
                slickObj = $('.slider-for').slick('getSlick');
            slickObj.slickGoTo(index);
        });

        // Fixed Header
        function fixHeader() {
            let scroll = $(window).scrollTop();
            if (scroll) {
                $('.header').addClass('header--fix');
                $('body').addClass('body--fix');
            } else {
                $('.header').removeClass('header--fix');
                $('body').removeClass('body--fix');
            }
        }
        fixHeader();
        $(window).scroll(function () {
            fixHeader();
        });

        // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        //     anchor.addEventListener('click', function (e) {
        //         e.preventDefault();
        //
        //         document.querySelector(this.getAttribute('href')).scrollIntoView({
        //             behavior: 'smooth',
        //         });
        //     });
        // });

        $('.header__menu > ul > .has__mega-menu').hover(
            function () {
                $('body').addClass('megamenu--open');
            }, function () {
                $('body').removeClass('megamenu--open');
            }
        );

        $('.sf-level-0').prepend('<div class="toggle__icon"></div>');

        $('.sf-level-0').on('click',function(){
           $(this).find('.children').toggleClass('children--active')
        });

        $('.toggle__icon').on('click',function(){
            $(this).closest('.children').toggleClass('children--active')
            $(this).toggleClass('toggle__icon--minus');
        });

        if($('body').hasClass('page-template-contact')) {
           $('.menu-locaties').removeClass('active');
        }

        $('.single-opendag .single__sidebar').theiaStickySidebar({additionalMarginTop: 100});

        tippy('[data-tippy-content]', {
            theme: 'custom',
        });
    },
    finalize() {
    },
};
